import React, { lazy, Suspense, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import axios from "axios"
import {
  Redirect,
  Route,
  Switch,
  BrowserRouter as Router,
} from "react-router-dom"
import {
  Spin,
  message,
  Modal,
  ConfigProvider,
  theme as themeConfig,
} from "antd"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"

import { updatePageState } from "../data/redux/page_details/actions"
import { resetReduxState } from "../data/redux/admin_details/actions"

import {
  getDataFromLocalStorage,
  setDataInLocalStorage,
  checkInternetConnection,
} from "../data/utils/dataUtility"

import lightThemeToken from "../data/theme/light"
import darkThemeToken from "../data/theme/dark"
import { LOCAL_STORAGE, METHOD_TYPES } from "../data/constants/common"
import { ROUTE_PATH } from "../data/constants/layout"
import { CLOUD_URL } from "../data/config/urls"
import { log, isAppleDevice, throttled } from "../data/utils/common"
import { getLang, checkDevice } from "../data/utils/device"

import ProtectedRoute from "./components/ProtectedRoute"
import ProtectedRedirect from "./components/ProtectedRedirect"
import ErrorBoundary from "./components/ErrorBoundary"
import GlobalInformation from "./components/GlobalInformation"
import MicrosoftAuthentication from "./components/MicrosoftAuthentication"
import AppBanner from "./components/AppBanner"

dayjs.extend(relativeTime)

const NotFound = lazy(() => import("./components/NotFound"))
const AuthContainer = lazy(() => import("./AuthContainer"))
const Login = lazy(() => import("./AuthContainer/Login"))
const ForgotPassword = lazy(() => import("./AuthContainer/ForgotPassword"))
const ResetPassword = lazy(() => import("./AuthContainer/ResetPassword"))
const PageContainer = lazy(() => import("./PageContainer"))
const Dashboard = lazy(() => import("./PageContainer/modules/Dashboard"))
const AgentMonitoring = lazy(() =>
  import("./PageContainer/modules/Analytics/AgentMonitoring")
)
const Feedback = lazy(() =>
  import("./PageContainer/modules/Analytics/Feedback")
)
const Kpi = lazy(() => import("./PageContainer/modules/Analytics/Kpi"))
const Chats = lazy(() => import("./PageContainer/modules/Chats"))
const History = lazy(() => import("./PageContainer/modules/History"))
const TicketHistory = lazy(() =>
  import("./PageContainer/modules/TicketHistory")
)
const Tickets = lazy(() => import("./PageContainer/modules/Tickets"))
const TicketView = lazy(() =>
  import("./PageContainer/modules/Tickets/TicketView")
)
const FAQBuilder = lazy(() =>
  import("./PageContainer/modules/BotBuilder/FAQBuilder")
)
const GenerativeFAQ = lazy(() =>
  import("./PageContainer/modules/BotBuilder/GenerativeFAQ")
)
const BrandFunctions = lazy(() =>
  import("./PageContainer/modules/BotBuilder/BrandFunctions")
)
const EditBrandFunction = lazy(() =>
  import("./PageContainer/modules/BotBuilder/BrandFunctions/EditBrandFunction")
)
const Intents = lazy(() => import("./PageContainer/modules/BotBuilder/Intents"))
const Events = lazy(() => import("./PageContainer/modules/BotBuilder/Events"))
const Entities = lazy(() =>
  import("./PageContainer/modules/BotBuilder/Entities")
)
const EnvironmentVariables = lazy(() =>
  import("./PageContainer/modules/BotBuilder/EnvironmentVariables")
)
const Workflows = lazy(() =>
  import("./PageContainer/modules/BotBuilder/Workflows")
)
const WorkflowDiagram = lazy(() =>
  import("./PageContainer/modules/BotBuilder/Workflows/WorkflowDiagram")
)
const Messages = lazy(() =>
  import("./PageContainer/modules/BotBuilder/Messages")
)
const EditMessage = lazy(() =>
  import("./PageContainer/modules/BotBuilder/Messages/EditMessage")
)
const BotParams = lazy(() =>
  import("./PageContainer/modules/BotBuilder/BotParams")
)
const Downtime = lazy(() => import("./PageContainer/modules/Settings/Downtime"))
const EventAnalytics = lazy(() =>
  import("./PageContainer/modules/Analytics/EventAnalytics")
)
const QueryAnalytics = lazy(() =>
  import("./PageContainer/modules/Analytics/QueryAnalytics")
)
const GenerativeAnalytics = lazy(() =>
  import("./PageContainer/modules/Analytics/GenerativeAnalytics")
)
const LiveChatAnalytics = lazy(() =>
  import("./PageContainer/modules/Analytics/LiveChatAnalytics")
)

const TicketAnalytics = lazy(() =>
  import("./PageContainer/modules/Analytics/TicketAnalytics")
)

const Fracture = lazy(() =>
  import("./PageContainer/modules/Analytics/Fracture")
)

const BotSuccessMetrics = lazy(() =>
  import("./PageContainer/modules/Analytics/BotSuccessMetrics")
)
const Agents = lazy(() => import("./PageContainer/modules/Members/Agents"))
const Roles = lazy(() => import("./PageContainer/modules/Members/Roles"))
const Groups = lazy(() => import("./PageContainer/modules/Members/Groups"))
const UsageAnalytics = lazy(() =>
  import("./PageContainer/modules/Analytics/UsageAnalytics")
)
const TeamMemberSetting = lazy(() =>
  import("./PageContainer/modules/Settings/TeamMemberSetting")
)
const MemberPermissions = lazy(() =>
  import("./PageContainer/modules/Settings/TeamMemberSetting/MemberPermissions")
)
const AddAndEditGroup = lazy(() =>
  import("./PageContainer/modules/Members/Groups/AddAndEditGroup")
)
const EditIntent = lazy(() =>
  import("./PageContainer/modules/BotBuilder/Intents/EditIntent")
)
const EditAgentProfile = lazy(() =>
  import("./PageContainer/modules/Members/Agents/EditAgentProfile")
)
const SessionFlow = lazy(() =>
  import("./PageContainer/modules/Analytics/SessionFlow")
)
const EditEntity = lazy(() =>
  import("./PageContainer/modules/BotBuilder/Entities/EditEntity")
)
const Conversions = lazy(() =>
  import("./PageContainer/modules/Analytics/Conversions")
)
const Reports = lazy(() => import("./PageContainer/modules/Console/Reports"))
const Tags = lazy(() => import("./PageContainer/modules/Members/Tags"))
const ChatlogAnalytics = lazy(() =>
  import("./PageContainer/modules/Analytics/ChatlogAnalytics")
)
const Customers = lazy(() => import("./PageContainer/modules/Customers"))
const CustomerProfile = lazy(() =>
  import("./PageContainer/modules/Customers/CustomerProfile")
)
const Profile = lazy(() => import("./PageContainer/modules/Settings/Profile"))
const ApiLogs = lazy(() => import("./PageContainer/modules/Console/ApiLogs"))
const ReportsUI = lazy(() =>
  import("./PageContainer/modules/Console/ReportsUI")
)
const CannedResponses = lazy(() =>
  import("./PageContainer/modules/Members/CannedResponses")
)
const TemplateMaster = lazy(() =>
  import("./PageContainer/modules/WhatsappTemplate/TemplateMaster")
)
const TemplateMainForm = lazy(() =>
  import(
    "./PageContainer/modules/WhatsappTemplate/TemplateMaster/TemplateMainForm"
  )
)
const Sender = lazy(() =>
  import("./PageContainer/modules/WhatsappTemplate/Sender")
)
const BroadcastHistory = lazy(() =>
  import("./PageContainer/modules/WhatsappTemplate/BroadcastHistory")
)
const TemaplateCta = lazy(() =>
  import("./PageContainer/modules/WhatsappTemplate/TemplateCta")
)
const Catalogue = lazy(() =>
  import("./PageContainer/modules/BotBuilder/Catalogue")
)
const EditCatalogueProduct = lazy(() =>
  import("./PageContainer/modules/BotBuilder/Catalogue/EditCatalogueProduct")
)
const Dialogues = lazy(() =>
  import("./PageContainer/modules/DialogueManagement/Dialogues")
)
const Domains = lazy(() =>
  import("./PageContainer/modules/DialogueManagement/Domains")
)
const Actions = lazy(() =>
  import("./PageContainer/modules/DialogueManagement/Actions")
)
const TrainingLogs = lazy(() =>
  import("./PageContainer/modules/DialogueManagement/TrainingLogs")
)
const WorkflowTree = lazy(() =>
  import("./PageContainer/modules/DialogueManagement/WorkflowTree")
)
const UsersJourneys = lazy(() =>
  import("./PageContainer/modules/DialogueManagement/UsersJourneys")
)
const JourneyTree = lazy(() =>
  import("./PageContainer/modules/DialogueManagement/UsersJourneys/JourneyTree")
)
const EditDialogue = lazy(() =>
  import("./PageContainer/modules/DialogueManagement/Dialogues/EditDialogue")
)
const MlConfiguration = lazy(() =>
  import("./PageContainer/modules/Settings/MlConfiguration")
)
const Configuration = lazy(() =>
  import("./PageContainer/modules/Members/Configuration")
)

const TestCases = lazy(() =>
  import("./PageContainer/modules/AutomatedTesting/TestCases")
)

const TestRuns = lazy(() =>
  import("./PageContainer/modules/AutomatedTesting/TestRuns")
)

const TestResults = lazy(() =>
  import("./PageContainer/modules/AutomatedTesting/TestRuns/TestResults")
)

const IntentClassifierModel = lazy(() =>
  import("./PageContainer/modules/ModelManagement/IntentClassifierModel")
)

const FAQModel = lazy(() =>
  import("./PageContainer/modules/ModelManagement/FAQModel")
)

const ModelAccuracyAnalytics = lazy(() =>
  import("./PageContainer/modules/Analytics/ModelAccuracyAnalytics")
)

const EditRole = lazy(() =>
  import("./PageContainer/modules/Members/Roles/EditRole")
)

const RaiseTicket = lazy(() =>
  import("./PageContainer/modules/Support/RaiseTicket"))

const App = ({ theme, actions }) => {
  log("APP rendered")

  const resizeTimerRef = useRef(null)
  const scrollTimerRef = useRef(null)
  const { updatePageState, resetReduxState } = actions

  useEffect(() => {
    const oriAdminDetails = getDataFromLocalStorage(
      LOCAL_STORAGE.ORI_ADMIN,
      null
    )
    axios({
      method: METHOD_TYPES.GET,
      params: { brand: oriAdminDetails?.brandInfo?.name },
      url: "/check_version",
      baseURL: CLOUD_URL,
    })
      .then(response => {
        const version = getDataFromLocalStorage(LOCAL_STORAGE.VERSION, -1)
        if (
          response &&
          response.data &&
          response.data.version &&
          response.data.version !== version
        ) {
          message.info(
            "your dashboard version has been changed so you need to login again."
          )
          setDataInLocalStorage(LOCAL_STORAGE.VERSION, response.data.version)
          resetReduxState()
        }
      })
      .catch(error => log("checkDashboardVersion error", error))
  }, [resetReduxState])

  useEffect(() => {
    const lang = getLang()
    updatePageState({
      lang: lang ? lang : "en",
      device_data: checkDevice.deviceStatus(),
    })

    const handleDeviceStatusChange = () => {
      if (resizeTimerRef.current) clearTimeout(resizeTimerRef.current)
      resizeTimerRef.current = setTimeout(
        () => updatePageState({ device_data: checkDevice.deviceStatus() }),
        750
      )
    }
    if (typeof window !== "undefined")
      window.addEventListener("resize", handleDeviceStatusChange)
    return () => {
      if (typeof window !== "undefined")
        window.removeEventListener("resize", handleDeviceStatusChange)
      if (resizeTimerRef.current) clearTimeout(resizeTimerRef.current)
    }
  }, [updatePageState])

  useEffect(() => {
    checkInternetConnection()
    if (typeof window !== "undefined") {
      window.addEventListener("online", checkInternetConnection)
      window.addEventListener("offline", checkInternetConnection)
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("online", checkInternetConnection)
        window.removeEventListener("offline", checkInternetConnection)
      }
    }
  }, [])

  useEffect(() => {
    const isApple = isAppleDevice()
    const handleScrollbarView = throttled(event => {
      if (event?.target?.style)
        event.target.style.setProperty("--scroll-display", "visible")
      if (event?.target?.classList)
        event.target.classList.add("prevent-inheritance")
      if (scrollTimerRef.current) {
        clearTimeout(scrollTimerRef.current)
      }
      scrollTimerRef.current = setTimeout(() => {
        if (event?.target?.style)
          event.target.style.setProperty("--scroll-display", "none")
      }, 800)
    }, 800)
    if (!isApple) {
      const element = document.getElementsByTagName("html")
      element[0].classList.add("scroll-visibility")
      if (typeof window !== "undefined")
        window.addEventListener("scroll", handleScrollbarView, true)
    }

    return () => {
      if (!isApple) {
        if (typeof window !== "undefined")
          window.removeEventListener("scroll", handleScrollbarView)
        if (scrollTimerRef.current) clearTimeout(scrollTimerRef.current)
      }
    }
  }, [])

  const getUserConfirmation = (message, callback) => {
    Modal.confirm({
      title: "Do you want to leave this page?",
      content: message,
      onOk: () => callback(true),
      onCancel: () => callback(false),
      okText: "Ok",
    })
  }

  return (
    <ConfigProvider
      theme={{
        token: theme === "dark" ? darkThemeToken : lightThemeToken,
        algorithm:
          theme === "dark"
            ? themeConfig.darkAlgorithm
            : themeConfig.defaultAlgorithm,
      }}
    >
      <div className={`ori-full-width ori-relative ori-theme-${theme}`}>
        {process.env.REACT_APP_ENABLE_MAINTAINANCE_ALERT && (<AppBanner />)}
        <ErrorBoundary>
          <GlobalInformation />
        </ErrorBoundary>
        <Router getUserConfirmation={getUserConfirmation}>
          <Switch>
            <Route
              exact
              path={ROUTE_PATH.MICROSOFT_AUTHENTICATION}
              component={MicrosoftAuthentication}
            />
            <Redirect exact from={ROUTE_PATH.AUTH} to={ROUTE_PATH.LOGIN} />
            <Route path={ROUTE_PATH.AUTH}>
              <ErrorBoundary sm>
                <Suspense fallback={null}>
                  <AuthContainer>
                    <ErrorBoundary sm>
                      <Suspense fallback={<FallbackLoader />}>
                        <Switch>
                          <Route path={ROUTE_PATH.LOGIN} component={Login} />
                          <Route
                            path={ROUTE_PATH.FORGOT_PASSWORD}
                            component={ForgotPassword}
                          />
                          <Route
                            path={ROUTE_PATH.RESET_PASSWORD}
                            component={ResetPassword}
                          />
                          <Route
                            path={ROUTE_PATH.ACTIVATE_USER}
                            component={ResetPassword}
                          />
                          <Route component={NotFound} />
                        </Switch>
                      </Suspense>
                    </ErrorBoundary>
                  </AuthContainer>
                </Suspense>
              </ErrorBoundary>
            </Route>
            <ErrorBoundary sm>
              <Suspense fallback={null}>
                <PageContainer>
                  <ErrorBoundary sm>
                    <Suspense fallback={<FallbackLoader />}>
                      <Switch>
                        <ProtectedRedirect
                          exact
                          from={ROUTE_PATH.ANALYTICS}
                          parent_path={ROUTE_PATH.ANALYTICS}
                        />
                        <ProtectedRedirect
                          exact
                          from={ROUTE_PATH.BOT_BUILDER}
                          parent_path={ROUTE_PATH.BOT_BUILDER}
                        />
                        <ProtectedRedirect
                          exact
                          from={ROUTE_PATH.DIALOGUE_MANAGEMENT}
                          parent_path={ROUTE_PATH.DIALOGUE_MANAGEMENT}
                        />
                        <ProtectedRedirect
                          exact
                          from={ROUTE_PATH.SETTINGS}
                          parent_path={ROUTE_PATH.SETTINGS}
                        />
                        <ProtectedRedirect
                          exact
                          from={ROUTE_PATH.MEMBERS}
                          parent_path={ROUTE_PATH.MEMBERS}
                        />
                        <ProtectedRedirect
                          exact
                          from={ROUTE_PATH.CONSOLE}
                          parent_path={ROUTE_PATH.CONSOLE}
                        />
                        <ProtectedRedirect
                          exact
                          from={ROUTE_PATH.AUTOMATED_TESTING}
                          parent_path={ROUTE_PATH.AUTOMATED_TESTING}
                        />
                        <ProtectedRedirect
                          exact
                          from={ROUTE_PATH.WHATSAPP_TEMPLATE}
                          parent_path={ROUTE_PATH.WHATSAPP_TEMPLATE}
                        />
                        <ProtectedRedirect
                          exact
                          from={ROUTE_PATH.MODEL_MANAGEMENT}
                          parent_path={ROUTE_PATH.MODEL_MANAGEMENT}
                        />
                        <ProtectedRoute
                          exact
                          path={ROUTE_PATH.DASHBOARD}
                          component={Dashboard}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.CHATS}
                          component={Chats}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.HISTORY}
                          component={History}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.TICKET_HISTORY}
                          component={TicketHistory}
                        />
                        <ProtectedRoute
                          exact
                          path={ROUTE_PATH.TICKETS}
                          component={Tickets}
                        />
                        <ProtectedRoute
                          exact
                          path={ROUTE_PATH.CUSTOMERS}
                          component={Customers}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.CUSTOMER_PROFILE}
                          sibling_path={ROUTE_PATH.CUSTOMERS}
                          component={CustomerProfile}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.USAGE_ANALYTICS}
                          parent_path={ROUTE_PATH.ANALYTICS}
                          component={UsageAnalytics}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.AGENT_MONITORING}
                          parent_path={ROUTE_PATH.ANALYTICS}
                          component={AgentMonitoring}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.EVENT_ANALYTICS}
                          parent_path={ROUTE_PATH.ANALYTICS}
                          component={EventAnalytics}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.CHATLOG_ANALYTICS}
                          parent_path={ROUTE_PATH.ANALYTICS}
                          component={ChatlogAnalytics}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.QUERY_ANALYTICS}
                          parent_path={ROUTE_PATH.ANALYTICS}
                          component={QueryAnalytics}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.GENERATIVE_ANALYTICS}
                          parent_path={ROUTE_PATH.ANALYTICS}
                          component={GenerativeAnalytics}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.BOT_SUCCESS_METRICS}
                          parent_path={ROUTE_PATH.ANALYTICS}
                          component={BotSuccessMetrics}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.MODEL_ACCURACY_ANALYTICS}
                          parent_path={ROUTE_PATH.ANALYTICS}
                          component={ModelAccuracyAnalytics}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.LIVE_CHAT_ANALYTICS}
                          parent_path={ROUTE_PATH.ANALYTICS}
                          component={LiveChatAnalytics}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.TICKET_ANALYTICS}
                          parent_path={ROUTE_PATH.ANALYTICS}
                          component={TicketAnalytics}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.FRACTURE}
                          parent_path={ROUTE_PATH.ANALYTICS}
                          component={Fracture}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.SESSION_FLOW}
                          parent_path={ROUTE_PATH.ANALYTICS}
                          component={SessionFlow}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.CONVERSIONS}
                          parent_path={ROUTE_PATH.ANALYTICS}
                          component={Conversions}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.FEEDBACK}
                          parent_path={ROUTE_PATH.ANALYTICS}
                          component={Feedback}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.KPI}
                          parent_path={ROUTE_PATH.ANALYTICS}
                          component={Kpi}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.EDIT_BRAND_FUNCTION}
                          parent_path={ROUTE_PATH.BRAND_FUNCTIONS}
                          grand_parent_path={ROUTE_PATH.BOT_BUILDER}
                          component={EditBrandFunction}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.MEMBER_PERMISSIONS}
                          parent_path={ROUTE_PATH.TEAM_MEMBER_SETTING}
                          grand_parent_path={ROUTE_PATH.SETTINGS}
                          component={MemberPermissions}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.ADD_GROUP}
                          parent_path={ROUTE_PATH.GROUPS}
                          grand_parent_path={ROUTE_PATH.MEMBERS}
                          component={AddAndEditGroup}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.EDIT_GROUP}
                          parent_path={ROUTE_PATH.GROUPS}
                          grand_parent_path={ROUTE_PATH.MEMBERS}
                          component={AddAndEditGroup}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.EDIT_INTENT}
                          parent_path={ROUTE_PATH.INTENTS}
                          grand_parent_path={ROUTE_PATH.BOT_BUILDER}
                          component={EditIntent}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.WORKFLOW_DIAGRAM}
                          parent_path={ROUTE_PATH.WORKFLOWS}
                          grand_parent_path={ROUTE_PATH.BOT_BUILDER}
                          component={WorkflowDiagram}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.TICKET_VIEW}
                          parent_path={ROUTE_PATH.TICKETS}
                          component={TicketView}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.EDIT_AGENT_PROFILE}
                          parent_path={ROUTE_PATH.AGENTS}
                          grand_parent_path={ROUTE_PATH.MEMBERS}
                          component={EditAgentProfile}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.TEST_RESULTS}
                          parent_path={ROUTE_PATH.TEST_RUNS}
                          grand_parent_path={ROUTE_PATH.AUTOMATED_TESTING}
                          component={TestResults}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.EDIT_ENTITY}
                          parent_path={ROUTE_PATH.ENTITIES}
                          grand_parent_path={ROUTE_PATH.BOT_BUILDER}
                          component={EditEntity}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.REPORTS}
                          parent_path={ROUTE_PATH.CONSOLE}
                          component={Reports}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.API_LOGS}
                          parent_path={ROUTE_PATH.CONSOLE}
                          component={ApiLogs}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.REPORTS_UI}
                          parent_path={ROUTE_PATH.CONSOLE}
                          component={ReportsUI}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.CREATE_TEMPLATE}
                          parent_path={ROUTE_PATH.TEMPLATE_MASTER}
                          grand_parent_path={ROUTE_PATH.WHATSAPP_TEMPLATE}
                          component={TemplateMainForm}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.EDIT_DIALOGUE}
                          parent_path={ROUTE_PATH.DIALOGUES}
                          grand_parent_path={ROUTE_PATH.DIALOGUE_MANAGEMENT}
                          component={EditDialogue}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.EDIT_MESSAGE}
                          parent_path={ROUTE_PATH.BOT_MESSAGES}
                          grand_parent_path={ROUTE_PATH.BOT_BUILDER}
                          component={EditMessage}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.EDIT_ROLE}
                          parent_path={ROUTE_PATH.ROLES}
                          grand_parent_path={ROUTE_PATH.MEMBERS}
                          component={EditRole}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.EDIT_CATALOGUE_PRODUCT}
                          parent_path={ROUTE_PATH.CATALOGUE}
                          grand_parent_path={ROUTE_PATH.BOT_BUILDER}
                          component={EditCatalogueProduct}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.JOURNEY_TREE}
                          parent_path={ROUTE_PATH.USERS_JOURNEYS}
                          grand_parent_path={ROUTE_PATH.DIALOGUE_MANAGEMENT}
                          component={JourneyTree}
                        />

                        <ProtectedRoute
                          path={ROUTE_PATH.INTENTCLASSIFIER_MODEL}
                          parent_path={ROUTE_PATH.MODEL_MANAGEMENT}
                          component={IntentClassifierModel}
                        />
                        <ProtectedRoute
                          path={ROUTE_PATH.FAQ_MODEL}
                          parent_path={ROUTE_PATH.MODEL_MANAGEMENT}
                          component={FAQModel}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.BOT_BUILDER}
                          path={ROUTE_PATH.WORKFLOWS}
                          component={Workflows}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.BOT_BUILDER}
                          path={ROUTE_PATH.INTENTS}
                          component={Intents}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.BOT_BUILDER}
                          path={ROUTE_PATH.BRAND_FUNCTIONS}
                          component={BrandFunctions}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.BOT_BUILDER}
                          path={ROUTE_PATH.EVENTS}
                          component={Events}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.BOT_BUILDER}
                          path={ROUTE_PATH.ENTITIES}
                          component={Entities}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.BOT_BUILDER}
                          path={ROUTE_PATH.ENVIRONMENT_VARIABLES}
                          component={EnvironmentVariables}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.BOT_BUILDER}
                          path={ROUTE_PATH.BOT_MESSAGES}
                          component={Messages}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.BOT_BUILDER}
                          path={ROUTE_PATH.BOT_PARAMS}
                          component={BotParams}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.BOT_BUILDER}
                          path={ROUTE_PATH.CATALOGUE}
                          component={Catalogue}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.BOT_BUILDER}
                          path={ROUTE_PATH.FAQ_BUILDER}
                          component={FAQBuilder}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.BOT_BUILDER}
                          path={ROUTE_PATH.GENERATIVE_FAQ}
                          component={GenerativeFAQ}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.MEMBERS}
                          path={ROUTE_PATH.AGENTS}
                          component={Agents}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.MEMBERS}
                          path={ROUTE_PATH.ROLES}
                          component={Roles}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.MEMBERS}
                          path={ROUTE_PATH.GROUPS}
                          component={Groups}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.MEMBERS}
                          path={ROUTE_PATH.CANNED_RESPONSES}
                          component={CannedResponses}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.MEMBERS}
                          path={ROUTE_PATH.TAGS}
                          component={Tags}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.MEMBERS}
                          path={ROUTE_PATH.CONFIGURATION}
                          component={Configuration}
                        />

                        <ProtectedRoute
                          parent_path={ROUTE_PATH.DIALOGUE_MANAGEMENT}
                          path={ROUTE_PATH.DIALOGUES}
                          component={Dialogues}
                        />

                        <ProtectedRoute
                          parent_path={ROUTE_PATH.DIALOGUE_MANAGEMENT}
                          path={ROUTE_PATH.DOMAINS}
                          component={Domains}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.DIALOGUE_MANAGEMENT}
                          path={ROUTE_PATH.ACTIONS}
                          component={Actions}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.DIALOGUE_MANAGEMENT}
                          path={ROUTE_PATH.TRAINING_LOGS}
                          component={TrainingLogs}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.DIALOGUE_MANAGEMENT}
                          path={ROUTE_PATH.WORKFLOW_TREE}
                          component={WorkflowTree}
                        />

                        <ProtectedRoute
                          parent_path={ROUTE_PATH.DIALOGUE_MANAGEMENT}
                          path={ROUTE_PATH.USERS_JOURNEYS}
                          component={UsersJourneys}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.SETTINGS}
                          path={ROUTE_PATH.DOWNTIME}
                          component={Downtime}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.SETTINGS}
                          path={ROUTE_PATH.PROFILE}
                          component={Profile}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.SETTINGS}
                          path={ROUTE_PATH.TEAM_MEMBER_SETTING}
                          component={TeamMemberSetting}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.SETTINGS}
                          path={ROUTE_PATH.ML_CONFIGURATION}
                          component={MlConfiguration}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.AUTOMATED_TESTING}
                          path={ROUTE_PATH.TEST_CASES}
                          component={TestCases}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.AUTOMATED_TESTING}
                          path={ROUTE_PATH.TEST_RUNS}
                          component={TestRuns}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.WHATSAPP_TEMPLATE}
                          path={ROUTE_PATH.TEMPLATE_MASTER}
                          component={TemplateMaster}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.WHATSAPP_TEMPLATE}
                          path={ROUTE_PATH.SENDER}
                          component={Sender}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.WHATSAPP_TEMPLATE}
                          path={ROUTE_PATH.BROADCAST_HISTORY}
                          component={BroadcastHistory}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.WHATSAPP_TEMPLATE}
                          path={ROUTE_PATH.TEMPLATE_RCA}
                          component={TemaplateCta}
                        />
                        <ProtectedRedirect
                          exact
                          from={ROUTE_PATH.SUPPORT}
                          parent_path={ROUTE_PATH.SUPPORT}
                        />
                        <ProtectedRoute
                          parent_path={ROUTE_PATH.SUPPORT}
                          path={ROUTE_PATH.RAISE_TICKET}
                          component={RaiseTicket}
                        />
                        <ProtectedRoute component={NotFound} />
                      </Switch>
                    </Suspense>
                  </ErrorBoundary>
                </PageContainer>
              </Suspense>
            </ErrorBoundary>
          </Switch>
        </Router>
      </div>
    </ConfigProvider>
  )
}

const FallbackLoader = () => {
  const { token } = themeConfig.useToken()
  return (
    <div className="ori-full-width ori-full-parent-height ori-flex-row ori-flex-center">
      <Spin size="large" />
      <span
        style={{
          marginLeft: token.marginSM,
          color: token.colorPrimary,
        }}
      >
        {" "}
        Loading...
      </span>
    </div>
  )
}

const mapStateToProps = state => ({
  theme: state.page_details.theme,
})

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        updatePageState,
        resetReduxState,
      },
      dispatch
    ),
  }
}

App.propTypes = {
  actions: PropTypes.object,
  theme: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
